import React from "react";
import { Link } from "react-router-dom";
import api from "../../Environment";
import Helper from "../Helper/helper";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import configuration from "react-global-configuration";
import { translate } from "react-multi-lang";
// import SocialButton from "../Helper/SocialButton";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';

var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const REDIRECT_URI = window.location.href;

class LoginCommponent extends Helper {
  state = {
    data: {
      email: "",
      password: "",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
  };

 
  handleSocialLoginFailure = (err) => {
    console.error(err);
    this.setState({
      loadingContent: null,
      buttonDisable: false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // const { toastManager } = this.props;
    this.setState({
      loadingContent: this.props.t("button_loading"),
      buttonDisable: true,
    });
    api
      .postMethod("v4/login", this.state.data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.props.history.push("/view-profiles");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  responseFacebook = (fb_response) => {
    console.log(fb_response);
    const { path } = this.props.location;
    if (fb_response) {
      if (fb_response.status == "unknown") {
        ToastDemo(this.props.toastManager, "Cancelled", "error");
        return false;
      }
      const emailAddress =
      fb_response.email === undefined || fb_response.email === null
          ? fb_response.id + "@facebook.com"
          : fb_response.email;

      const facebookLoginInput = {
        social_unique_id: fb_response.userID,
        login_by: "facebook",
        email: emailAddress,
        name: fb_response.name,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };
      api
        .postMethod("v4/register", facebookLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("push_status", response.data.data.push_status);
            localStorage.setItem("userType", response.data.data.user_type);

            localStorage.setItem("username", response.data.data.name);
            localStorage.setItem(
              "active_profile_id",
              response.data.data.sub_profile_id
            );
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.props.history.push("/view-profiles");
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          ToastDemo(this.props.toastManager, error, "error");
          this.setState({ loadingContent: null, buttonDisable: false });
        });
    }
  };

  responseGoogle = (response) => {
    console.log(response);
    const path = this.props.location;
    let googleData = response;
    if (response == undefined || response == null || !response) {
      return ToastDemo(
        this.props.toastManager,
        "Google login failed.",
        "error"
      );
    }
    const googleLoginInput = {
      social_unique_id: googleData.sub,
      login_by: "google",
      email: googleData.email,
      name: googleData.name,
      picture: googleData.picture,
      device_type: "web",
      device_token: "123466",
      timezone: const_time_zone,
    };

    api
      .postMethod("v4/register", googleLoginInput)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userType", response.data.data.user_type);
          localStorage.setItem("push_status", response.data.data.push_status);
          localStorage.setItem("username", response.data.data.name);
          localStorage.setItem(
            "active_profile_id",
            response.data.data.sub_profile_id
          );
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.props.history.push("/view-profiles");
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        } else {
          ToastDemo(
            this.props.toastManager,
            response.data.error_messages,
            "error"
          );
          this.setState({
            loadingContent: null,
            buttonDisable: false,
          });
        }
      })
      .catch((error) => {
        ToastDemo(this.props.toastManager, error, "error");
        this.setState({
          loadingContent: null,
          buttonDisable: false,
        });
      });
  };

  render() {
    const { t } = this.props;
    var bgImg = {
      backgroundImage: `url(${configuration.get(
        "configData.common_bg_image"
      )})`,
    };
    const { data } = this.state;

    return (
      <div>
        <div className="common-bg-img" style={bgImg}>
          <div className="auth-page-header">
            <Link to={"/"}>
              <img
                src={configuration.get("configData.site_logo")}
                className="site-logo"
                alt="logo_img"
              />
            </Link>
          </div>

          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 col-xl-4 auto-margin">
              <div className="register-box">
                <h3 className="register-box-head">{t("signin")}</h3>
                <form
                  onSubmit={this.handleSubmit}
                  className="auth-form login-new-form"
                >
                  <div className="form-group">
                    {/*<label htmlFor="email">{t("email_address")}</label>*/}
                    <input
                      type="email"
                      onChange={this.handleChange}
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder={t("enter_email_address")}
                      value={data.email}
                    />
                  </div>
                  <div className="form-group">
                    {/*<label htmlFor="pwd">{t("password")}</label>*/}
                    <input
                      type="password"
                      onChange={this.handleChange}
                      className="form-control"
                      id="pwd"
                      name="password"
                      placeholder={t("enter_password")}
                      value={data.password}
                    />
                  </div>
                  <p className="mt-4">
                    <Link to={"/forgot-password"} className="btn-link">
                      {t("forgot_password")}
                    </Link>
                  </p>
                  <button
                    className="btn btn-danger auth-btn"
                    disabled={this.state.buttonDisable}
                  >
                    {this.state.loadingContent != null
                      ? this.state.loadingContent
                      : t("signin")}
                  </button>
                </form>
                <div>
                  {/* {configuration.get("configData.social_logins.FB_CLIENT_ID") ? (
                    <SocialButton
                      provider="facebook"
                      appId={configuration.get("configData.social_logins.FB_CLIENT_ID")}
                      onLoginSuccess={this.responseFacebook}
                      onLoginFailure={this.handleSocialLoginFailure}
                      className="social"
                      id="facebook-connect"
                    >
                      <i className="fab fa-facebook fb social-icons" />{" "}
                      {t("login_with")} {t("facebook")}
                    </SocialButton>
                  ) : (
                    ""
                  )} */}
                </div>
                <div>
                  {configuration.get("configData.social_logins.FB_CLIENT_ID") ? (
                    <LoginSocialFacebook
                      appId={configuration.get("configData.social_logins.FB_CLIENT_ID")}
                      fieldsProfile={
                        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                      }
                      // onLoginStart={onLoginStart}
                      // onLoginSuccess={this.responseFacebook}
                      // onLoginFailure={this.handleSocialLoginFailure}
                      redirect_uri={REDIRECT_URI}
                      onResolve={({ provider, data }: IResolveParams) => {
                        this.responseFacebook(data);
                      }}
                      onReject={err => {
                        console.log(err);
                      }}
                    >
                      <FacebookLoginButton />
                    </LoginSocialFacebook>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {configuration.get(
                    "configData.social_logins.GOOGLE_CLIENT_ID"
                  ) == ""
                    ? ""
                    : <LoginSocialGoogle
                      client_id={configuration.get("configData.social_logins.GOOGLE_CLIENT_ID")}
                      // onLoginSuccess={this.responseGoogle}
                      // onLoginFailure={this.handleSocialLoginFailure}
                      redirect_uri={REDIRECT_URI}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ provider, data }: IResolveParams) => {
                        this.responseGoogle(data);
                      }}
                      onReject={err => {
                        console.log(err);
                      }}
                    >
                      <GoogleLoginButton />
                    </LoginSocialGoogle>
                  }
                </div>

                <p className="auth-link">
                  <span className="grey-text">{t("new_to_website")} </span>
                  <Link to={"/register"} className="btn-link">
                    {t("sign_up_now")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(translate(LoginCommponent));
